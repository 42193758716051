import { Outlet } from 'react-router-dom'
import AppHeader from './AppHeader/AppHeader'
import Footer from './Footer/Footer'

const Layout = () => {
	return (
		<div className='wrapper'>
			<AppHeader />
			<Outlet />

			<div className='footer'>
				<Footer />
			</div>
		</div>
	)
}

export default Layout
