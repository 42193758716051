import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

const contentStyle = {
	margin: 0,
	color: '#fff',
	lineHeight: '160px',
	textAlign: 'center',
	background: '#58c584',
}

const imageStyle = {
	width: '100%',
	height: '100%',
	objectFit: 'cover', // Изображение масштабируется без искажения
	maxHeight: '450px',
}

const Portfolio = () => {
	const [houses, setHouses] = useState([])
	const { params } = useParams()

	useEffect(() => {
		// Замените 'https://your-api-endpoint.com/houses' на реальный URL API
		fetch('https://goodhome38api.ru/products/')
			.then((response) => response.json())
			.then((data) => setHouses(data))
			.catch((error) => console.error('Ошибка при получении данных:', error))
	}, [])

	return (
		<div id='OurWorks' className='portfolio'>
			<div className='container'>
				<div className='title text-center'>
					<h5>Портфолио</h5>
					<h2>Наши построенные дома</h2>
				</div>

				{houses.map((house, index) => {
					// Разбиваем массив на группы по 2 элемента
					if (index % 2 === 0) {
						return (
							<div key={index} className='row'>
								<div className='col-md-6 mb-4'>
									<div className='carousel-wrapper'>
										<a href={`/product/${house.url}`}>
											<img
												src={house.photos[0].image}
												style={{ ...contentStyle, ...imageStyle }}
												alt='imag'
											/>
										</a>
									</div>
									<div className='text'>
										<h5>
											<a className='profile-title' href={`/product/${house.url}`}>
												{house.title}
											</a>
										</h5>
										<p>{house.short_description}</p>
									</div>
								</div>

								{index + 1 < houses.length && (
									<div className='col-md-6 mb-4'>
										<div className='carousel-wrapper'>
											<a href={`/product/${houses[index + 1].url}`}>
												<img
													src={houses[index + 1].photos[0].image}
													style={{ ...contentStyle, ...imageStyle }}
													alt='imag'
												/>
											</a>
										</div>
										<div className='text'>
											<h5>
												<a className='profile-title' href={`/product/${houses[index + 1].url}`}>
													{houses[index + 1].title}
												</a>
											</h5>
											<p>{houses[index + 1].short_description}</p>
										</div>
									</div>
								)}
							</div>
						)
					}
					return null // Не рендерим, если индекс не четный
				})}
			</div>
		</div>
	)
}

export default Portfolio
