import React, { useState, useEffect } from 'react'
import { Carousel } from 'react-bootstrap'
import { Button, Modal, Image, Input, Checkbox } from 'antd'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Contacts from '../Contacts/Contacts'

const ProductPage = () => {
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [agreed, setAgreed] = useState(true)
	const [product, setProduct] = useState(null)
	const [activeIndex, setActiveIndex] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const { url } = useParams() // Get the URL parameter

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleOk = async () => {
		if (agreed) {
			try {
				await axios.post('https://goodhome38api.ru/send-email/', { name, phone })
				alert('Заявка отправлена!')
				setIsModalOpen(false)
				setName('')
				setPhone('')
				setAgreed(false)
			} catch (error) {
				alert('Ошибка при отправке заявки. Попробуйте еще раз.')
			}
		} else {
			alert('Пожалуйста, согласитесь на обработку персональных данных.')
		}
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		// Fetch product data using the API
		const fetchProduct = async () => {
			try {
				const response = await axios.get(`https://goodhome38api.ru/product/${url}`) // Replace with your API endpoint
				setProduct(response.data)
			} catch (error) {
				console.error('Error fetching product:', error)
			}
		}

		fetchProduct()
	}, [url]) // Dependency on URL to fetch on route change

	if (!product) {
		return <div>Loading...</div> // Show a loading state while fetching data
	}

	// Extract data from the fetched product object
	const { title, short_description, description, price, photos, characteristics, planning_photos } = product

	return (
		<div className='product'>
			<div className='container mt-5'>
				<div className='row'>
					<div className='col-md-5'>
						<Carousel activeIndex={activeIndex} onSelect={(index) => setActiveIndex(index)}>
							{photos.map((photo, index) => (
								<Carousel.Item key={index}>
									<Image
										className='d-block w-100 h-100'
										src={`https://goodhome38api.ru${photo.image}`}
										alt={`${title} ${index + 1}`}
										style={{ objectFit: 'cover', width: '100%', height: 'auto' }}
									/>
								</Carousel.Item>
							))}
						</Carousel>
					</div>
					<div className='col-md-7'>
						<h1 className='mb-3'>{title}</h1>
						<p className='mb-4'>{description}</p>
						<div className='ipoteka__item'>
							<div className='ipoteka__item-title'>Стоимость строительства</div>
							<div className='ipoteka__item-value'>{price}</div>
						</div>
						<div className='ipoteka__button pt-3'>
							<Button className='ipoteka__button-value' onClick={showModal}>
								Заказать смету
							</Button>
							<Modal
								title='Оставить заявку'
								visible={isModalOpen}
								okText='Отправить'
								cancelText='Отменить'
								onOk={handleOk}
								onCancel={handleCancel}
							>
								<Input
									placeholder='Ваше имя'
									className='bid-form'
									value={name}
									onChange={(e) => setName(e.target.value)}
									style={{ marginBottom: '10px' }}
								/>
								<Input
									placeholder='Ваш телефон'
									className='bid-form'
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
									style={{ marginBottom: '10px' }}
								/>
								<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
									Я согласен на обработку персональных данных
								</Checkbox>
							</Modal>
						</div>
						<h3 className='mb-3 mt-3'>Характеристики</h3>
						<ul className='list-group'>
							{characteristics.map((characteristic) => (
								<li key={characteristic.name} className='list-group-item'>
									{characteristic.name}: {characteristic.value}
								</li>
							))}
						</ul>
					</div>
				</div>
				{/* Display planning photos */}
				<div className='product-layout'>
					<h2 className='mb-3 mt-3'>Планировка</h2>
					<div className='layout-block'>
						<div className='row'>
							{planning_photos.map((photo, index) => (
								<div key={index} className='col-md-6 col-12 layout-item'>
									<Image
										width={300}
										height={200} // Adjust image dimensions as needed
										src={`https://goodhome38api.ru${photo.image}`}
										style={{
											objectFit: 'contain',
											width: '100%',
											height: '100%',
											maxHeight: '200px',
											maxWidth: '300px',
										}}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
				<Contacts />
			</div>
		</div>
	)
}

export default ProductPage
