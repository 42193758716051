import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'

const Questions = () => {
	const [questions, setQuestions] = useState([])
	const url = useSelector(selectSiteUrl)

	useEffect(() => {
		fetch(`${url}Questions/`)
			.then((response) => response.json())
			.then((data) => setQuestions(data))
			.catch((error) => console.error(error))
	}, [])

	return (
		<div id='FAQ' className='container mt-5'>
			<div className='title text-center'>
				<h2>Часто задаваемые вопросы</h2>
			</div>
			<div className='accordion' id='faqAccordion'>
				{questions.map((question) => (
					<div key={question.id} className='accordion-item'>
						<h2 className='accordion-header' id={'faqHeading' + question.id}>
							<button
								className='accordion-button collapsed'
								type='button'
								data-bs-toggle='collapse'
								data-bs-target={'#faqCollapse' + question.id}
								aria-expanded='true'
								aria-controls={'faqCollapse' + question.id}
							>
								{question.question}
							</button>
						</h2>
						<div
							id={'faqCollapse' + question.id}
							className='accordion-collapse collapse'
							aria-labelledby={'faqHeading' + question.id}
							data-bs-parent='#faqAccordion'
						>
							<div className='accordion-body'>{question.answer}</div>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default Questions
