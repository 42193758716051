import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const AppHeader = () => {
	const [categories, setCategories] = useState([])
	const url = useSelector(selectSiteUrl)

	useEffect(() => {
		if (url) {
			console.log(url)
			fetch(`${url}NavbarCategory`)
				.then((response) => response.json())
				.then((data) => setCategories(data))
				.catch((error) => console.error('Error fetching data:', error))
		}
	}, [url])

	return (
		<div id='navbar' className='navbar navbar-expand-lg'>
			<div className='container'>
				<a href='/' className='navbar-brand'>
					Добрый дом<span>.</span>
				</a>
				<button
					className='navbar-toggler'
					type='button'
					data-bs-toggle='collapse'
					data-bs-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<FontAwesomeIcon className='fa-icon' icon={faBars} />
				</button>

				<div className='collapse navbar-collapse' id='navbarSupportedContent'>
					<ul className='navbar-nav ms-auto'>
						{categories.map((category) => (
							<li key={category.id} className='nav-item'>
								<a className='nav-link' href={'/#' + category.anchor}>
									{category.category_name}
								</a>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default AppHeader
