import { useState, useEffect } from 'react'
import warranty from '../../img/warranty.png'
import { useSelector } from 'react-redux'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'

const Features = () => {
	const [advantages, setAdvantages] = useState([])
	const url = useSelector(selectSiteUrl)

	useEffect(() => {
		fetch(`${url}Advantages/`)
			.then((response) => response.json())
			.then((data) => setAdvantages(data))
			.catch((error) => console.error('Error fetching data:', error))
	}, [])

	return (
		<div id='Advantages' className='features'>
			<div className='container'>
				<div className='content'>
					<div className='row'>
						<div className='col-md-12'>
							<div className='content-box'>
								<div className='text'>
									<h2>Преимущества строительства с нами</h2>
								</div>
							</div>
						</div>
					</div>
					<div className='row'>
						{advantages.map((advantage, index) => (
							<div key={advantage.id} className='col-md-6'>
								<div className='content-box'>
									<div className='icon'>
										<img src={advantage.advantages_image} alt={advantage.advantages_title} />
									</div>
									<div className='text'>
										<h5>{advantage.advantages_title}</h5>
										<p>{advantage.advantages_description}</p>
									</div>
								</div>
								{index % 2 !== 0 && <div className='w-100'></div>}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Features
