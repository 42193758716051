import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVk, faTelegram } from '@fortawesome/free-brands-svg-icons'

const Footer = () => {
	return (
		<div id='contact' className='contact text-center'>
			<div className='container'>
				<h5>Звоните нам</h5>
				<h2>+7 (3952) 65-25-00</h2>
				<p>goodhome38@mail.ru</p>
				<ul>
					<li>
						<a href='https://vk.com/kindhome38' target='_blank' rel='noreferrer noopener'>
							<FontAwesomeIcon className='fa-icon' icon={faVk} />
						</a>
					</li>
					<li>
						<a href='https://t.me/kindhome38' target='_blank' rel='noreferrer noopener'>
							<FontAwesomeIcon className='fa-icon' icon={faTelegram} />
						</a>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Footer
