import { Button, Modal, Input, Checkbox } from 'antd'
import axios from 'axios'
import { useState } from 'react'

const StartBuilding = () => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [agreed, setAgreed] = useState(true)

	const showModal = () => {
		setIsModalOpen(true)
	}

	const handleOk = async () => {
		if (agreed) {
			try {
				await axios.post('https://goodhome38api.ru/send-email/', { name, phone })
				alert('Заявка отправлена!')
				setIsModalOpen(false)
				setName('')
				setPhone('')
				setAgreed(false)
			} catch (error) {
				alert('Ошибка при отправке заявки. Попробуйте еще раз.')
			}
		} else {
			alert('Пожалуйста, согласитесь на обработку персональных данных.')
		}
	}

	const handleCancel = () => {
		setIsModalOpen(false)
	}

	return (
		<div className='app'>
			<div className='background-image'></div>
			<div className='container'>
				<div className='row startbuilding'>
					<div className='col-md-6 col-sm-12 pt-4 pb-4'>
						<h2>Узнайте, с чего начать строительство вашего дома</h2>
						<p>Наши консультанты ответят на все ваши вопросы, дадут оценку и рекомендации вашему проекту</p>
					</div>
					<div className='col-md-3 col-sm-12 '></div>
					<div className='col-md-3 col-sm-12 pb-4'>
						<Button type='primary' className='button start-building' onClick={showModal}>
							Оставить заявку
						</Button>
						<Modal
							title='Оставить заявку'
							visible={isModalOpen}
							okText='Отправить'
							cancelText='Отменить'
							onOk={handleOk}
							onCancel={handleCancel}
						>
							<Input
								placeholder='Ваше имя'
								className='bid-form'
								value={name}
								onChange={(e) => setName(e.target.value)}
								style={{ marginBottom: '10px' }}
							/>
							<Input
								placeholder='Ваш телефон'
								className='bid-form'
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
								style={{ marginBottom: '10px' }}
							/>
							<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
								Я согласен на обработку персональных данных
							</Checkbox>
						</Modal>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StartBuilding
