import React, { useState } from 'react'

import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVk, faTelegram } from '@fortawesome/free-brands-svg-icons'

const Contacts = () => {
	const [center] = useState([52.289588, 104.280606]) // Замените координатами
	return (
		<div id='Contacts' className='pt-5'>
			<div className='row contacts-container'>
				<div className='col-md-3 col-12 mb-4'>
					<div className='contact contacts-info'>
						<h2 className='pt-5'>Наши контакты</h2>
						<ul className='pt-2'>
							<li>
								<h3>г.Иркутск</h3>
							</li>
							<li className='pt-2'>
								<h3>+7 (3952) 65-25-00</h3>
							</li>
							<li className='pt-2'>
								<h3>goodhome38@mail.ru</h3>
							</li>
						</ul>
						<div id='contact' className='contact-card text-center'>
							<ul>
								<li>
									<a href='https://vk.com/kindhome38' target='_blank' rel='noreferrer noopener'>
										<FontAwesomeIcon className='fa-icon' icon={faVk} />
									</a>
								</li>
								<li>
									<a href='https://t.me/kindhome38' target='_blank' rel='noreferrer noopener'>
										<FontAwesomeIcon className='fa-icon' icon={faTelegram} />
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className='col-md-9 col-12 maps'>
					<YMaps>
						<Map defaultState={{ center, zoom: 15 }} width='101%' height='100%'>
							<Placemark
								geometry={center}
								options={{
									preset: 'islands#redDotIcon', // Используйте пресет для метки
								}}
							/>
						</Map>
					</YMaps>
				</div>
			</div>
		</div>
	)
}

export default Contacts
