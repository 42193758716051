import AppLowerHeader from '../AppLowerHeader/AppLowerHeader'
import Features from '../Features/Features'
import Portfolio from '../Portfolio/Portfolio'
import AboutUs from '../AboutUs/AboutUs'
import AppHeader from '../AppHeader/AppHeader'
import StartBuilding from '../StartBuilding/StartBuilding'
import Questions from '../Questions/Questions'
import Contacts from '../Contacts/Contacts'

const AppBody = () => {
	return (
		<>
			<AppHeader />
			<AppLowerHeader />
			<Features />
			<Portfolio />
			<Questions />
			<AboutUs />
			<div className='container pb-5'>
				<Contacts />
			</div>
			<StartBuilding />
		</>
	)
}

export default AppBody
