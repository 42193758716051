import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import { Provider } from 'react-redux'
import { Routes, Route } from 'react-router-dom'
import Layout from './Components/Layout'
import AppBody from './Components/AppBody/AppBody'
import { createRoot } from 'react-dom/client'
import ProductPage from './Components/ProductPage/ProductPage'

createRoot(document.getElementById('root')).render(
	<BrowserRouter>
		<Provider store={store}>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<AppBody />} />
					<Route path='product/:url' element={<ProductPage />} />
				</Route>
			</Routes>
		</Provider>
	</BrowserRouter>
)
