import AboutUsImg from '../../img/aboutus.jpg'

const AboutUs = () => {
	return (
		<div id='AboutUs' className='contact text-center'>
			<div className='container'>
				<div className='row featurette'>
					<div className='col-md-7 order-md-2'>
						<h2 className='featurette-heading'>О нашей компании</h2>
						<p className='lead'>
							🏡 Наша компания специализируется на строительстве частных домов в Иркутске из различных
							материалов, таких как бетон и дерево. Мы предлагаем индивидуальный подход к каждому проекту,
							учитывая пожелания и потребности клиента. 🌲 Строим дома, которые сочетают в себе качество,
							надежность и уют, создавая комфортное пространство для жизни. 🏗️ Наша команда профессионалов
							обладает большим опытом в строительстве и гарантирует высокое качество работ. 🔨 Мы
							используем современные технологии и материалы, чтобы каждый дом был долговечным и
							функциональным. 🌟 Доверьте нам строительство вашего идеального дома, и мы превратим ваши
							мечты в реальность! 🏠 Вместе мы создадим уникальное жилище, которое будет радовать вас и
							вашу семью долгие годы!
						</p>
					</div>
					<div className='col-md-5 order-md-1'>
						<img src={AboutUsImg} alt='Добрый дом'></img>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AboutUs
