import home from '../../img/home.png'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { selectSiteUrl } from '../../features/siteUrl/siteUrlSlice'
import { Button, Modal, Input, Checkbox } from 'antd'
import axios from 'axios'

const AppLowerHeader = () => {
	const [name, setName] = useState('')
	const [phone, setPhone] = useState('')
	const [agreed, setAgreed] = useState(true)
	const [title, setTitle] = useState({ main_banner_text: '' })
	const url = useSelector(selectSiteUrl)
	const [isModalOpen, setIsModalOpen] = useState(false)
	const showModal = () => {
		setIsModalOpen(true)
	}
	const handleOk = async () => {
		if (agreed) {
			try {
				await axios.post('https://goodhome38api.ru/send-email/', { name, phone })
				alert('Заявка отправлена!')
				setIsModalOpen(false)
				setName('')
				setPhone('')
				setAgreed(false)
			} catch (error) {
				alert('Ошибка при отправке заявки. Попробуйте еще раз.')
			}
		} else {
			alert('Пожалуйста, согласитесь на обработку персональных данных.')
		}
	}
	const handleCancel = () => {
		setIsModalOpen(false)
	}

	useEffect(() => {
		if (url) {
			console.log(url)
			fetch(`${url}MainPage`)
				.then((response) => response.json())
				.then((data) => setTitle(data[0]))
				.catch((error) => console.error('Error fetching data:', error))
		}
	}, [url])

	return (
		<div id='home' className='intro'>
			<div className='container'>
				<div className='row align-items-center header-items'>
					<div className='col-md-6'>
						<div className='content'>
							<h1 style={{ wordWrap: 'break-word' }}>{title.main_banner_text}</h1>
							<p>{title.main_banner_description}</p>
							<ul>
								<li>
									<Button className='button header-button' onClick={showModal}>
										Оставить заявку
									</Button>
									<Modal
										title='Оставить заявку'
										visible={isModalOpen}
										okText='Отправить'
										cancelText='Отменить'
										onOk={handleOk}
										onCancel={handleCancel}
									>
										<Input
											placeholder='Ваше имя'
											className='bid-form'
											value={name}
											onChange={(e) => setName(e.target.value)}
											style={{ marginBottom: '10px' }}
										/>
										<Input
											placeholder='Ваш телефон'
											className='bid-form'
											value={phone}
											onChange={(e) => setPhone(e.target.value)}
											style={{ marginBottom: '10px' }}
										/>
										<Checkbox checked={agreed} onChange={(e) => setAgreed(e.target.checked)}>
											Я согласен на обработку персональных данных
										</Checkbox>
									</Modal>
								</li>
							</ul>
						</div>
					</div>
					<div className='col-md-6'>
						<div className='content-image'>
							<img src={home} alt='home' />
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AppLowerHeader
